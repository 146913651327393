<template>
    <div class="detail relative sm:w-full md:w-full lg:w-full mx-auto self-center">
        <div class="header flex">
            <div class="back flex items-center justify-center mr-auto" @click="back">
                <feather-icon icon="ArrowLeftIcon"></feather-icon>
            </div>
            <div class="title flex items-center uppercase">Edit data</div>
            <div class="save flex items-center justify-center ml-auto" @click.prevent="openConfirm">
                <feather-icon icon="TrashIcon"></feather-icon>
            </div>
        </div>
        <div class="body sm:w-full md:w-full lg:w-full mx-auto self-center">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <label class="vs-input--label">
                        Story
                    </label>
                    <quill-editor v-model="form.header_description" :options="editorOption"></quill-editor>
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-select class="w-full" v-model="form.header_position"
                        label="Select Position Story">
                        <vs-select-item value="top" text="Top Background" />
                        <vs-select-item value="bottom" text="Bottom Background" />
                        <vs-select-item value="center" text="Center Background" />
                        <vs-select-item value="left" text="Left Background" />
                        <vs-select-item value="left" text="Right Background" />
                    </vs-select>
                </div>
                <div class="error-msg" v-if="validation.hasError('form.header_position')">
                        {{validation.firstError('form.header_position')}}
                    </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <label class="vs-input--label mb-3">
                        Header Background
                    </label>
                    <picture-input :z-index="90" ref="headerBackground" :prefill="base64Thumbnail" width="300"
                        height="120" margin="16" accept="image/*" size="2" :alertOnError="false" @error="errorThumbnail"
                        removeButtonClass="vs-component vs-button vs-button-primary vs-button-gradient small w-1/4"
                        button-class="vs-component vs-button w-1/4 vs-button-warning vs-button-border small"
                        :removable="false" :custom-strings="{
                                    upload: '<h1>Bummer!</h1>',
                                    drag: 'Upload'
                                }">
                    </picture-input>
                </div>
            </div>
            <div class="vx-row mb-4">
                <div class="vx-col w-full">
                    <vs-button class="w-full" icon-pack="feather" icon="icon-check" @click.prevent="save">
                        Save</vs-button>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-button class="w-full" type="flat" color="dark" @click="back" icon-pack="feather"
                        icon="icon-arrow-left">
                        Back</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import SimpleVueValidation from 'simple-vue-validator'
    import PictureInput from 'vue-picture-input'
    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";

    import {
        quillEditor
    } from "vue-quill-editor";
    const Validator = SimpleVueValidation.Validator

    Vue.use(SimpleVueValidation)
    export default {
        data() {
            return {
                form: {
                    header_position: '',
                    header_description: '',
                    id: ''
                },
                base64Thumbnail: '',
                editorOption: {
                    modules: {
                        toolbar: {
                            container: [
                                ["bold", "italic", "underline", "strike"],
                                [{
                                    align: []
                                }],
                                [{
                                    header: [2, 3, 4, 5, 6, false]
                                }],
                                [{
                                        list: "ordered"
                                    },
                                    {
                                        list: "bullet"
                                    }
                                ],
                                ["blockquote", "code-block"],
                                [{
                                    color: []
                                }, {
                                    background: []
                                }],
                            ]
                        },
                        history: {
                            delay: 1000,
                            maxStack: 50,
                            userOnly: false
                        }
                    }
                }
            }
        },
        validators: {
            'form.header_position': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value).required()
                }
            },
        },
        mounted() {
            this.loadData()
        },
        components: {
            PictureInput,
            "quill-editor": quillEditor,
        },
        methods: {
            back() {
                this.$router.go(-1)
            },
            errorThumbnail(error) {
                this.$vs.notify({
                    title: 'Oops!',
                    text: error.message,
                    color: 'danger',
                    position: 'top-center'
                })
            },
            async loadData() {
                this.$vs.loading()
                await this.getData().then(resp => {
                    this.form.header_position = resp.header_position
                    this.form.header_description = resp.header_story
                    if (resp.header_image) {
                        this.getThumbnail()
                    }
                })
                this.$vs.loading.close()
            },
            getGroupParam() {
                try {
                    this.form.id = this.$route.params.id
                } catch {
                    this.$router.push('/')
                }
            },
            getData() {
                this.getGroupParam()
                return new Promise((resolve, reject) => {
                    const data = {
                        id: this.form.id
                    }
                    this.$http({
                            url: 'v1/id/storyheader/retrieve',
                            method: 'POST',
                            data: data
                        })
                        .then(response => {
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
            },
            dataURLtoFile(dataurl, filename) {
                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, {
                    type: mime
                });
            },
            getThumbnail() {
                this.$vs.loading()
                this.actionGetThumbnail().then(response => {
                    this.$vs.loading.close()
                    if (response) {
                        this.base64Thumbnail = this.dataURLtoFile(response, "thumbnail-" + this.form
                            .id +
                            ".png")
                    }
                })
            },
            actionGetThumbnail() {
                return new Promise((resolve, reject) => {
                    const data = {
                        id: this.form.id
                    }
                    this.$http({
                            url: 'v1/id/storyheader/thumbnail',
                            method: 'POST',
                            data: data
                        })
                        .then(resp => {
                            const data = resp.data.serve
                            resolve(data)
                        })
                        .catch(err => {
                            reject(err)
                        })
                })
            },
            save() {
                this.$validate().then(result => {
                    if (result) {
                        this.actionSave().then(() => {
                                this.$vs.notify({
                                    title: 'Success!',
                                    text: 'Saved!',
                                    color: 'success',
                                    position: 'top-center'
                                })
                                this.back()
                            })
                            .catch(err => {
                                this.$vs.notify({
                                    title: 'Oops!',
                                    text: err.response ? err.response.data.message :
                                        'Something wrong, ' + err,
                                    color: 'danger',
                                    position: 'top-center'
                                })
                                this.$vs.loading.close()
                            })
                    }
                })
            },
            actionSave() {
                // console.log(this.$refs)
                return new Promise((resolve, reject) => {
                    const fd = new FormData;
                    fd.append('id', this.form.id)
                    fd.append('header_background_image', this.$refs.headerBackground.file)
                    fd.append('header_position', this.form.header_position)
                    fd.append('header_description', this.form.header_description)
                    fd.append("_method", "PUT");
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/id/storyheader',
                            method: 'POST',
                            data: fd
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            delete() {
                this.actionDelete().then(() => {
                        this.$vs.notify({
                            title: 'Success!',
                            text: 'Deleted!',
                            color: 'success',
                            position: 'top-center'
                        })
                        this.back()
                    })
                    .catch(err => {
                        this.$vs.notify({
                            title: 'Oops!',
                            text: err.response ? err.response.data.message : 'Something wrong, ' + err,
                            color: 'danger',
                            position: 'top-center'
                        })
                        this.$vs.loading.close()
                    })

            },
            actionDelete() {
                return new Promise((resolve, reject) => {
                    const data = {
                        id: this.form.id
                    }
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/id/storyheader',
                            method: 'DELETE',
                            data: data
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            openConfirm() {
                this.$vs.dialog({
                    type: 'confirm',
                    color: 'danger',
                    title: `Delete`,
                    text: 'Are you sure?click `delete` to delete data.',
                    accept: this.delete,
                    acceptText: 'Delete'
                })
            },
            clearForm() {

            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/vuesax/_variables.scss";

    .detail {
        min-height: 400px;
        background-color: #ffffff;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, .05);
        border-radius: .5rem;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .header {
            padding-top: 20px;
            margin-bottom: 40px;

            .help-toggle {
                background: rgba(var(--vs-primary), 1) !important !important;

                span {
                    font-size: 16px;
                    color: rgba(var(--vs-primary), 1) !important !important;
                }
            }

            .back {
                width: 40px;
                height: 40px;
                background: rgba(0, 0, 0, .05);
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .title {
                color: #444444;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }

            .save {
                width: 40px;
                height: 40px;
                background: rgba(var(--vs-primary), 1) !important;
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    color: $white;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .body {
            .now {
                font-size: 25px;
                text-align: center;
                margin-top: 20%;
            }

            .error-msg {
                color: $danger;
                font-size: 11px
            }

            .icons {
                width: 100%;

                li {
                    width: 40px;
                    height: 40px;
                    border-radius: 3px;
                    border: 1px solid transparent;
                    transition: all .5s;
                    cursor: pointer;

                    .feather-icon {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        background: rgb(255, 255, 255);
                        border: 1px solid rgb(248, 249, 250);
                        box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .05);
                    }

                    &.active {
                        background: rgba(var(--vs-primary), 1) !important;

                        .feather-icon {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
</style>